
interface prodEdit {
  idProduto: string;
  codProdutoLoja: string;
  idLoja: any;
  produto: string;
  nomeReferencia: string;
  preco: string;
  grupoExibicao: string;
  estoque: number;
  imgProdutoUploader: any;
  videoProduto: any;
  lubricanteFracionado: any;
  updated_at: any;
  created_at: any;
  alterado: any;
  unidade: string;
  informacao_extra: string;
}

import {
  defineComponent,
  Ref,
  ref,
  onUpdated,
  onMounted,
  onBeforeMount,
  onUnmounted,
  watch,
} from "vue";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import useAlert from "@/composables/Alert";
import { number } from "yup/lib/locale";
import Button from "@/components/Button.vue"

export default defineComponent({
  name: "ModalTelaProduto",
  components: {
    Button,
  },
  emits: ["attProdutos", "closeModal"],
  props: { product: Object, idLoja: Number },

  setup(props, { emit }) {
    let auxModal;
    const { showTimeAlert } = useAlert();
    const json: Ref<any> = ref({});
    const Jsonfiltered: Ref<any> = ref({});
    const nomeEmpresaDinamico: Ref<any> = ref("");
    const fileInput: Ref<any> = ref(null);
    const spanWarning = ref(false);
    const produto: Ref<prodEdit> = ref({
      idProduto: "",
      codProdutoLoja: "",
      idLoja: 0,
      produto: "",
      nomeReferencia: "",
      preco: "",
      grupoExibicao: "",
      estoque: 0,
      imgProdutoUploader: "",
      videoProduto: "",
      lubricanteFracionado: "",
      updated_at: "",
      created_at: "",
      alterado: "",
      unidade: "",
      informacao_extra: "",
    });
    const isCreate: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);
    const imageSrc: Ref<string> = ref("");
    const imageFormData = ref("");
    // const currencyMask = {
    //   // decimal: ',',
    //   // thousands: '.',
    //   // prefix: 'R$ ',
    //   // suffix: '',
    //   // precision: 2,
    //   // masked: false
    // }
  
    const grupos = [
      "OUTROS",
      "ACESSORIOS",
      "ADITIVO CAMBIO",
      "ADITIVO COMBS",
      "ADITIVO MOTOR",
      "ADITIVO RADIADOR",
      "BATERIA",
      "BAZAR",
      "CAR CARE",
      "COMBO",
      "FILTRO DE AR",
      "FILTRO DE CABINE",
      "FILTRO DE COMBUSTIVEL",
      "FILTRO DE OLEO",
      "FLUIDO DE FREIO",
      "FLUIDO DIRECAO AUTOMATICA",
      "FLUIDO RADIADOR",
      "ODORIZANTES",
      "OLEO DE CAMBIO",
      "OLEO HIDRAULICO",
      "OLEO MOTOR",
      "PALHETAS",
      "TRANSMISSAO E CAMBIO",
    ];

    async function updateProduct() {
      const { estoque } = produto.value;
      produto.value.preco = produto.value.preco.replace(/[^\d,]/g, "");
      const attProduto = { ...produto.value, estoque: Number(estoque) };
      try {
        const res = await Api.post("updateProduto", { ...attProduto });
      } catch (error) {
        console.log(error);
        showTimeAlert("Não foi possivel atualizar o produto.", "error");
      } finally {
        showTimeAlert("Produto atualizado com sucesso!");
        emit("closeModal");
        emit("attProdutos");
      }
    }
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };
    getToken();

    async function createProduct() {
      try {
        produto.value.preco = produto.value.preco.replace(/[^\d,]/g, "");

        const res = await Api.post(
          "novoProduto",
          { ...produto.value },
          { headers: { "Content-Type": "multipart/form-data" } }
        );
      } catch (error) {
        console.log(error);
        showTimeAlert("Não foi possivel criar o produto.", "error");
      } finally {
        emit("attProdutos");
        emit("closeModal");
        showTimeAlert("Produto criado com sucesso!");
      }
    }

    async function deleteProduct() {
      const { estoque } = produto.value;
      const attProduto = { ...produto.value, estoque: Number(estoque) };
      try {
        const res = await Api.post("deletarProduto", { ...attProduto });
      } catch (error) {
        console.log(error);
        showTimeAlert("Não foi possivel excluir o produto.", "error");
      } finally {
        showTimeAlert("Produto excluido com sucesso!");
        emit("attProdutos");
        emit("closeModal");
        dialogVisible.value = false;
      }
    }

    function verifySelectValues(e) {
      e.preventDefault();
      console.log("verificou")
      if (
        !produto.value.produto ||
        !produto.value.preco ||
        !produto.value.estoque ||
        !produto.value.codProdutoLoja ||
        !produto.value.grupoExibicao ||
        !produto.value.unidade
      ) {
        spanWarning.value = true;
        showTimeAlert("Preencha os campos obrigatorios.", "error");
        return true;
      }
      spanWarning.value = false;
      createProduct();
    }
    

    const openInput = () => fileInput.value.click();
    
    watch(
      () => props.product,
      () => {
        // se o codProduto vier undefined é para usar a função createProduct no botão
        if (!props.product?.codProduto) {
          isCreate.value = true;
        } else {
          isCreate.value = false;
        }

        if (props.product) {
          produto.value = {
            idProduto: props.product.codProduto,
            codProdutoLoja: props.product.codProdutoLoja,
            idLoja: props.idLoja,
            produto: props.product.produto,
            nomeReferencia: props.product.nomeReferencia,
            preco: props.product.preco,
            grupoExibicao: props.product.grupoExibicao,
            estoque: props.product.estoque,
            imgProdutoUploader: props.product.imgProduto,
            videoProduto: props.product.videoProduto,
            lubricanteFracionado: props.product.lubricanteFracionado,
            updated_at: props.product.updated_at,
            created_at: props.product.created_at,
            alterado: props.product.alterado,
            unidade: props.product.unidade,
            informacao_extra: props.product.informacao_extra,
          };
        }
      }
    );

    return {
      produto,
      grupos,
      updateProduct,
      deleteProduct,
      isCreate,
      createProduct,
      dialogVisible,
      verifySelectValues,
      spanWarning,
      openInput,
      fileInput,
    };
  },
});
